import Web3 from 'web3';
import siteConfig from '../configs/site.config';
import presaleABI from '../abis/PresaleToken.json'
import usdtABI from '../abis/USDToken.json'
import coin7ABI from '../abis/Coin7.json'
import crowdABI from '../abis/Crowdsale.json'

const getPresaleContractInstance = () => {
  const web3 = new Web3(Web3.givenProvider);
  const res = new web3.eth.Contract(presaleABI.abi, siteConfig.PRESALE_CONTRACT_ADDRESS);
  return res
}
const getUSDTContractInstance = () => {
  const web3 = new Web3(Web3.givenProvider);
  const res = new web3.eth.Contract(usdtABI.abi, siteConfig.USDT_CONTRACT_ADDRESS);
  return res
}
const getCoin7ContractInstance = () => {
  const web3 = new Web3(Web3.givenProvider);
  const res = new web3.eth.Contract(coin7ABI.abi, siteConfig.COIN7_CONTRACT_ADDRESS);
  return res
}
const getCrowdSaleContractInstance = () => {
  const web3 = new Web3(Web3.givenProvider);
  const res = new web3.eth.Contract(crowdABI.abi, siteConfig.CROWDSALE_CONTRACT_ADDRESS);
  return res
}

const getAccounts = async () => {
  const web3 = new Web3(Web3.givenProvider);
  if (web3.currentProvider.isMetaMask === true) {
    try {
      const accounts = await web3.eth.getAccounts();
      return Promise.resolve({
        type: "metamask",
        account: accounts[0]
      })
    } catch (err) {
      return Promise.reject(err)
    }
  } else {
    try {
      const accounts = await web3.eth.getAccounts();
      return Promise.resolve({
        type: "wallet",
        account: accounts[0]
      })
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export {
  getPresaleContractInstance,
  getUSDTContractInstance,
  getCoin7ContractInstance,
  getCrowdSaleContractInstance,
  getAccounts
};