/* eslint-disable no-unused-vars */
import { getCoin7ContractInstance, getCrowdSaleContractInstance, getPresaleContractInstance, getUSDTContractInstance } from './index';
import { utils } from "ethers";
import siteConfig from '../configs/site.config';

async function getCrowdSaleCoin7Balance() {
  try {
    const contract = getCoin7ContractInstance();
    const res = await contract.methods.balanceOf(siteConfig.CROWDSALE_CONTRACT_ADDRESS).call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}
async function withDrawCoin7FromCrowdSale(acount) {
  try {
    const contractInstance = getCrowdSaleContractInstance()
    const res = await contractInstance.methods.withdrawCoin7().send({
      from: acount
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}
async function getPresaleStage() {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.roundId().call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}
async function getPresalePhase() {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.currentPhase().call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}
async function getTokenManager() {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.tokenManager().call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getTotalSupply() {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.totalSupply().call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getTotalUSDT() {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.totalUSDTLiquidity().call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getTotalETH() {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.totalEthLiqudity().call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getCoin7Price() {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.lastTokenPrice().call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getCoin7NextPrice() {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.calculateNewPrice().call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getUserReferralCount(address) {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.userReferralCount(address).call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getPresaleRoundData(stage) {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.saleRounds(stage).call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getBalanceOfPresaleToken(address) {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.balanceOf(address).call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getAllReferrals() {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.getUserAllReferrals().call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getReferralData(address, id) {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.referralData(address, id).call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getCoin7Amount(ethAmount) {
  let amount = utils.parseEther(ethAmount)
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.getCoin7Amount(amount).call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}
async function getEthAmount(coin7Amount) {
  let amount = utils.parseUnits(coin7Amount, 18)
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.getEthAmount(amount).call()
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}
async function setPresalePhase(phase, address) {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.setPresalePhase(phase).send({
      from: address
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}
async function setCrowdSaleManager(crowdaddress, acount) {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.setCrowdsaleManager(crowdaddress).send({
      from: acount
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}
async function setEscrew(escrewAddress, acount) {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.setEscrewAddress(escrewAddress).send({
      from: acount
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}
async function startNextSaleRound(coin7Amount, endtime, account) {
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.startNextSaleRound(coin7Amount, endtime).send({
      from: account
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function approveUSDT(value, address) {
  let amount = utils.parseUnits(value, siteConfig.USDT_DECIMAL)
  try {
    const contractInstance = getUSDTContractInstance()
    const res = await contractInstance.methods.approve(siteConfig.PRESALE_CONTRACT_ADDRESS, amount).send({
      from: address
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function buyCoin7WithUSDT(address, value, referrer) {
  let amount = utils.parseUnits(value, siteConfig.USDT_DECIMAL)
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.buyTokensWithUSDT(amount, referrer).send({
      from: address
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function buyCoin7WithETH(address, value, referrer) {
  let price = value.toString();
  let cost = utils.parseEther(price)
  try {
    const contractInstance = getPresaleContractInstance()
    const res = await contractInstance.methods.buyTokensWithEth(referrer).send({
      from: address,
      value: cost
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

export {
  buyCoin7WithUSDT,
  buyCoin7WithETH,
  getPresaleStage,
  getCoin7Price,
  getPresaleRoundData,
  getCoin7Amount,
  getEthAmount,
  getCoin7NextPrice,
  approveUSDT,
  getAllReferrals,
  getBalanceOfPresaleToken,
  getReferralData,
  getUserReferralCount,
  getTokenManager,
  getTotalSupply,
  getTotalUSDT,
  getTotalETH,
  getPresalePhase,
  setPresalePhase,
  setCrowdSaleManager,
  startNextSaleRound,
  setEscrew,
  getCrowdSaleCoin7Balance,
  withDrawCoin7FromCrowdSale
}