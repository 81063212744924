/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useContext } from "react";
import { NotificationManager } from "react-notifications";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from 'date-fns/locale/de';
import Header from '../components/header';
import siteConfig from '../configs/site.config';
import Footer from '../components/footer';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ScaleLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './styles.css';
import { AppContext } from "../context/AppContext";
import { getCoin7Price, getCrowdSaleCoin7Balance, getPresalePhase, getPresaleStage, getTokenManager, getTotalETH, getTotalSupply, getTotalUSDT, setCrowdSaleManager, setEscrew, setPresalePhase, startNextSaleRound, withDrawCoin7FromCrowdSale } from "../apis/presale";


registerLocale('de', de)
const Home = () => {
  const {
    account,
    connectWallet,
    disconnectWallet
  } = useContext(AppContext);


  const [loading, setLoading] = useState(false);
  const [loadingPhase, setLoadingPhase] = useState(false);
  const [loadingStage, setLoadingStage] = useState(false);
  const [loadingCrowd, setLoadingCrowd] = useState(false);
  const [loadingEscrew, setLoadingEscrew] = useState(false);
  const [loadingWithdraw, setLoadingWithDraw] = useState(false);
  // eslint-disable-next-line
  let [color, setColor] = useState("#ffffff");

  const [presaleStage, setStage] = useState(0);
  const [priceCoin7, setCoin7Price] = useState(0);
  const [priceCoin7InUSDT, setCoin7PriceInUSDT] = useState(0);
  const [soldAmount, setSoldAmount] = useState(0);
  const [totalUsdt, setTotalUsdt] = useState(0);
  const [totalEth, setTotalEth] = useState(0);
  const [crowdSaleCoin7, setCrowdSaleCoin7] = useState(0);
  const [manager, setManager] = useState("");

  const [currentPhase, setCurrentPhase] = useState(0);
  const [nextPresaleAmount, setNextPresaleAmount] = useState("");
  const [nextPresaleTimeLine, setNextPresaleTimeLine] = useState("");
  const [crowdsaleAddress, setCrowdSaleAddress] = useState("");
  const [escrewAddress, setEscrewAddress] = useState("");
  const [timeStamp, setTimeStamp] = useState(0);

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

  useEffect(() => {
    handleReload();
    // eslint-disable-next-line
  }, [])

  const handleReload = () => {
    getPresaleStage()
      .then((res) => {
        const value = parseInt(res);
        setStage(value);
      })
      .catch((err) => {
        console.log(err);
      })
    getPresalePhase()
      .then((res) => {
        const value = parseInt(res);
        setCurrentPhase(value);
      })
      .catch((err) => {
        console.log(err);
      })
    getCrowdSaleCoin7Balance()
      .then((res) => {
        const price = parseFloat(res) / 10 ** 18;
        console.log("crowdcoin7", price)
        setCrowdSaleCoin7(price)
      })
      .catch((err) => {
        console.log(err);
      })
    getTokenManager()
      .then((res) => {
        setManager(res);
      })
      .catch((err) => {
        console.log(err);
      })

    getCoin7Price()
      .then((res) => {
        const price = parseFloat(res) / 10 ** 8;
        const priceUsdt = 1 / price;
        setCoin7Price(price);
        setCoin7PriceInUSDT(priceUsdt);
      })
      .catch((err) => {
        console.log(err);
      })
    getTotalETH()
      .then((res) => {
        const price = parseFloat(res) / 10 ** 18;
        setTotalEth(price);
      })
      .catch((err) => {
        console.log(err);
      })
    getTotalUSDT()
      .then((res) => {
        const price = parseFloat(res) / 10 ** siteConfig.USDT_DECIMAL;
        // console.log(price)
        setTotalUsdt(price);
      })
      .catch((err) => {
        console.log(err);
      })
    getTotalSupply()
      .then((res) => {
        const price = parseFloat(res) / 10 ** 18;
        setSoldAmount(price);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const handleConnect = () => {
    connectWallet()
      .then((res) => {
      })
      .catch((error) => {
        NotificationManager.warning('Warning', error.message, 3000);
        setLoading(false);
      })
  }

  const handlePhases = async () => {
    setLoadingPhase(true);

    if (manager === account) {
      setPresalePhase(currentPhase, account)
        .then((res) => {
          setLoadingPhase(false);
          NotificationManager.success('Success', "Updated Presale Phase", 5000);
          handleReload();
        })
        .catch((err) => {
          console.log(err);
          setLoadingPhase(false);
        })
    } else {
      NotificationManager.error('Error', "You are not Manager", 5000);
      setLoadingPhase(false);
    }
  }

  const handleChangePhase = (event) => {
    const value = event.target.value;
    setCurrentPhase(value);
  }

  const handleStages = async () => {
    setLoadingStage(true);
    if (nextPresaleAmount > 0 && timeStamp > 0) {
      if (manager === account) {
        startNextSaleRound(nextPresaleAmount, timeStamp, account)
          .then((res) => {
            setLoadingStage(false);
            NotificationManager.success('Success', "Next Presale Round Started", 5000);
            handleReload();
          })
          .catch((err) => {
            console.log(err);
            setLoadingStage(false);
          })
      } else {
        NotificationManager.error('Error', "You are not Manager", 5000);
        setLoadingStage(false);
      }
    } else {
      NotificationManager.warning('Warning', "Incorrect Values", 5000);
      setLoadingStage(false);
    }

  }

  const handleChangeNextAmount = (event) => {
    const value = event.target.value;
    setNextPresaleAmount(value)
  }
  const handleChangeNextTime = (value) => {
    const timeStamp = Math.floor(new Date(value).getTime() / 1000);
    console.log(timeStamp);
    setNextPresaleTimeLine(value)
    setTimeStamp(timeStamp);
  }
  const handleChangeCrowdSaleAddress = (event) => {
    const value = event.target.value;
    setCrowdSaleAddress(value)
  }
  const handleChangeEscrewAddress = (event) => {
    const value = event.target.value;
    setEscrewAddress(value)
  }

  const handleCrowdSale = async () => {
    setLoadingCrowd(true);
    if (crowdsaleAddress.length > 11) {
      if (manager === account) {
        setCrowdSaleManager(crowdsaleAddress, account)
          .then((res) => {
            setLoadingCrowd(false);
            NotificationManager.success('Success', "CrowdSale Manager Changed", 5000);
            handleReload();
          })
          .catch((err) => {
            console.log(err);
            setLoadingCrowd(false);
          })
      } else {
        NotificationManager.error('Error', "You are not Manager", 5000);
        setLoadingCrowd(false);
      }
    } else {
      NotificationManager.warning('Warning', "Incorrect address", 5000);
      setLoadingCrowd(false);
    }
  }
  const handleEscrewAddress = async () => {
    setLoadingEscrew(true);
    if (escrewAddress.length > 11) {
      if (manager === account) {
        setEscrew(escrewAddress, account)
          .then((res) => {
            setLoadingEscrew(false);
            NotificationManager.success('Success', "Escrew Address Changed", 5000);
            handleReload();
          })
          .catch((err) => {
            console.log(err);
            setLoadingEscrew(false);
          })
      } else {
        NotificationManager.error('Error', "You are not Manager", 5000);
        setLoadingEscrew(false);
      }
    } else {
      NotificationManager.warning('Warning', "Incorrect address", 5000);
      setLoadingEscrew(false);
    }
  }
  const handleClaimCoin7 = async () => {
    setLoadingWithDraw(true);
    if (manager === account) {
      withDrawCoin7FromCrowdSale(account)
        .then((res) => {

          setLoadingWithDraw(false);
          NotificationManager.success('Success', "Withdraw Successed", 5000);
          handleReload();
        })
        .catch((err) => {
          console.log(err);
          setLoadingWithDraw(false);
        })
    } else {
      NotificationManager.error('Error', "You are not Manager", 5000);
      setLoadingWithDraw(false);
    }

  }

  return (
    <>
      <Header address={account} connect={handleConnect} disconnectWallet={disconnectWallet} />

      <section className="main-section" id="phases">
        <div className="container">
          <div className="row align-items-center gy-5">
            <div className="col-lg-12">
              <div className="title" style={{ paddingTop: '50px' }}>
                <h1>Coin7 Presale Control</h1>
              </div>
              <div className="row align-items-center py-5">
                <div className="col-lg-5">
                  <div className="text-center main-gif">
                    <img src="/images/h2_about_img.png" alt="" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="main-box-lg">
                    <p>Presale Stage <b>{presaleStage + 1}</b></p>
                    <p>Total <b>{soldAmount.toFixed(2)}</b> Coin7 Sold</p>
                    <p>Escrew saved <b>{totalEth.toFixed(2)}</b>  ETH</p>
                    <p>Escrew saved <b>{totalUsdt.toFixed(2)}</b> USDT</p>
                    <p>Current Price $<b>{priceCoin7}</b> Coin7</p>
                    <p>1 USDT = <b>{priceCoin7InUSDT.toFixed(2)}</b> Coin7</p>
                    <p>CrowdSaleManager saved <b>{crowdSaleCoin7.toFixed(2)}</b> Coin7</p>
                  </div>
                </div>
              </div>
              {account ? (
                <>
                  <div className="flex-center">
                    <div className="main-counter">
                      <div className="title">
                        <h2>Set Presale Phases</h2>
                      </div>
                      <p></p>
                      <div >
                        <select
                          className="input-container"
                          value={currentPhase}
                          onChange={handleChangePhase}
                        >
                          <option className="select-option" value={0}>Created</option>
                          <option className="select-option" value={1}>Running</option>
                          <option className="select-option" value={2}>Paused</option>
                          <option className="select-option" value={3}>Migrating</option>
                          <option className="select-option" value={4}>Migrated</option>
                        </select>
                        <ul className="navbar-nav mr-auto">
                          <li style={{ display: 'flex', justifyContent: 'center' }}>
                            {
                              !loadingPhase && <a className="custom-btn" id="connect-wallet" onClick={handlePhases}>
                                Apply
                              </a>
                            }
                          </li>
                          <ClipLoader color={color} loading={loadingPhase} css={override} size={20} />
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="flex-center mt-5">
                    <div className="main-counter">
                      <div className="title">
                        <h2>Start Next Presale Stage</h2>
                      </div>
                      <p></p>
                      <div >
                        <input
                          className="input-container"
                          name="amount"
                          type={'number'}
                          placeholder="Next presale coin7 Amount"
                          value={nextPresaleAmount}
                          onChange={handleChangeNextAmount}
                        />
                        <DatePicker
                          selected={nextPresaleTimeLine}
                          style={{ width: '100%' }}
                          locale="de"
                          showTimeSelect
                          placeholderText="Next presale End time"
                          timeFormat="HH:mm"
                          timeIntervals={20}
                          timeCaption="time"
                          dateFormat="yyyy-MM-dd HH:mm"
                          onChange={(date) => handleChangeNextTime(date)}
                        />
                        <ul className="navbar-nav mr-auto mt-5">
                          <li style={{ display: 'flex', justifyContent: 'center' }}>
                            {
                              !loadingStage && <a className="custom-btn" id="connect-wallet" onClick={handleStages}>
                                Apply
                              </a>
                            }
                          </li>
                          <ClipLoader color={color} loading={loadingStage} css={override} size={20} />
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="flex-center mt-5">
                    <div className="main-counter">
                      <div className="title">
                        <h2>Set Crowdsale Manager</h2>
                      </div>
                      <p></p>
                      <div >
                        <input
                          className="input-container"
                          name="crowd"
                          type={'text'}
                          placeholder="CrowdSale contract address"
                          value={crowdsaleAddress}
                          onChange={handleChangeCrowdSaleAddress}
                        />
                        <ul className="navbar-nav mr-auto">
                          <li style={{ display: 'flex', justifyContent: 'center' }}>
                            {
                              !loadingCrowd && <a className="custom-btn" id="connect-wallet" onClick={handleCrowdSale}>
                                Apply
                              </a>
                            }
                          </li>
                          <ClipLoader color={color} loading={loadingCrowd} css={override} size={20} />
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="flex-center mt-5">
                    <div className="main-counter">
                      <div className="title">
                        <h2>Set Escrew Address</h2>
                      </div>
                      <p></p>
                      <div >
                        <input
                          className="input-container"
                          name="crowd"
                          type={'text'}
                          placeholder="Escrew wallet address"
                          value={escrewAddress}
                          onChange={handleChangeEscrewAddress}
                        />
                        <ul className="navbar-nav mr-auto">
                          <li style={{ display: 'flex', justifyContent: 'center' }}>
                            {
                              !loadingEscrew && <a className="custom-btn" id="connect-wallet" onClick={handleEscrewAddress}>
                                Apply
                              </a>
                            }
                          </li>
                          <ClipLoader color={color} loading={loadingEscrew} css={override} size={20} />
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="flex-center mt-5">
                    <div className="main-counter">
                      <div className="title">
                        <h2>WithDraw Coin7 From CrowdSaleManager</h2>
                      </div>
                      <p></p>
                      <div >
                        <ul className="navbar-nav mr-auto">
                          <li style={{ display: 'flex', justifyContent: 'center' }}>
                            {
                              !loadingWithdraw && <a className="custom-btn" id="connect-wallet" onClick={handleClaimCoin7}>
                                WithDraw
                              </a>
                            }
                          </li>
                          <ClipLoader color={color} loading={loadingWithdraw} css={override} size={20} />
                        </ul>
                      </div>
                    </div>
                  </div>

                </>
              ) : (
                <div className="main-button" id="navbarText">
                  <ul className="navbar-nav mr-auto">
                    <a className="custom-btn" id="connect-wallet" onClick={handleConnect}>
                      Connect Wallet
                    </a>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Home;