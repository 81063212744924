/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

const Header = (props) => {
  const { address, connect, disconnectWallet } = props
  return (
    <nav className="navbar navbar-expand-lg navbar-light nav-custom fixed-top bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="./index.html">
          <img src="images/logo.png" className="logo" style={{ width: '150px' }} />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarText">
          <ul className="navbar-nav nav mr-auto">
            {
              !address ?
                (<a className="custom-btn" id="connect-wallet" onClick={connect}>
                  Connect Wallet
                </a>)
                :
                (<a className="custom-btn" id="connect-wallet" onClick={disconnectWallet}>
                  Disconnect Wallet
                </a>)
            }
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;